import React from "react";
import Routes from "Routes";
import { createGlobalStyle } from "styled-components";
import { AuthenticationProvider } from "hooks/useAuthenticationState";

const GlobalStyles = createGlobalStyle`
  html, body {
    height:100%
  }

  /* https://github.com/reach/router/issues/63#issuecomment-428050999 */
  div[role="group"][tabindex] {
    width: 100%;
  }
`;

const Root = () => {
  return (
    <>
      <GlobalStyles />
      <AuthenticationProvider>
        <Routes />
      </AuthenticationProvider>
    </>
  );
};

export default Root;
