import React, { createContext, useState, useContext, useEffect } from "react";
import { auth } from "firebaseSdk";

const AuthenticationContext = createContext({
  isAuthenticated: false,
  isLoaded: false,
  currentUser: null
});

export const AuthenticationProvider = ({ children }) => {
  const [authenticationState, setAuthenticationState] = useState({
    isAuthenticated: false,
    currentUser: null
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      setAuthenticationState({
        isLoaded: true,
        isAuthenticated: Boolean(currentUser),
        currentUser
      });
    });
    return unsubscribe;
  }, []);

  return (
    <AuthenticationContext.Provider value={authenticationState}>
      {children}
    </AuthenticationContext.Provider>
  );
};

const useAuthenticationState = () => {
  const authenticationState = useContext(AuthenticationContext);
  return authenticationState;
};

export default useAuthenticationState;
