import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import * as config from "config";

const app = firebase.initializeApp({
  apiKey: config.FIREBASE_API_KEY,
  authDomain: config.FIREBASE_AUTH_DOMAIN,
  databaseURL: config.FIREBASE_DATABASE_URL,
  projectId: config.FIREBASE_PROJECT_ID,
  storageBucket: config.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.FIREBASE_MESSAGING_SENDER_ID
});

const db = app.firestore();
const functions = firebase.functions();

export default app;

// Auth
export const auth = app.auth();

// Collections
export const usersCollection = db.collection("users");
export const companiesCollection = db.collection("companies");

// Functions
export const handleEarlyAccessSignup = functions.httpsCallable(
  "handleEarlyAccessSignup"
);
