import React, { Suspense } from "react";
import { Router } from "@reach/router";
import { HOME, SIGNUP, LOGIN, DASHBOARD, PEOPLE } from "./constants/paths";

/* Public Pages */
const Home = React.lazy(() => import("./pages/Home"));
const Signup = React.lazy(() => import("./pages/Signup"));
const Login = React.lazy(() => import("./pages/Login"));

/* App Pages */
const Dashboard = React.lazy(() => import("./app/Dashboard"));
const People = React.lazy(() => import("./app/People"));
const Employees = React.lazy(() => import("./app/People/pages/Employees"));

/* Employee Pages */
const AddNewEmployee = React.lazy(() =>
  import("./app/People/pages/Employees/pages/AddNewEmployee")
);
const Employee = React.lazy(() =>
  import("./app/People/pages/Employees/pages/Employee")
);

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Router>
        <Home path={HOME} />
        <Signup path={SIGNUP} />
        <Login path={LOGIN} />
        <Dashboard path={DASHBOARD} />
        <People path={PEOPLE}>
          <Employees path="/employees">
            <AddNewEmployee path="/add-new" />
            <Employee path=":employeeId" />
          </Employees>
        </People>
      </Router>
    </Suspense>
  );
};

export default Routes;
